import { readingTime as readingTimeHelper } from "@tryghost/helpers";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import * as React from "react";

import styled from "styled-components";
import InternalLink from "components/InternalLink";
import Pagination from "components/Pagination";
import GhostHead from "components/ghost/GhostHead";
import { Layout, PostCard, PostContent } from "../components";
import { formatDate, parseDateTime } from "../../../utils/dateTool";

const Article = styled.article`
    padding: max(8vmin, 40px) 0 max(8vmin, 64px);
    word-break: break-word;
`;

const ArticleHeader = styled.header`
    padding: 0 0 max(6.4vmin, 40px);
    display: grid;
    grid-template-columns:
        [full-start] minmax(max(4vmin, 20px), auto) [wide-start] minmax(
            auto,
            240px
        )
        [main-start] min(720px, calc(100% - max(8vmin, 40px)))
        [main-end] minmax(auto, 240px)
        [wide-end] minmax(max(4vmin, 20px), auto) [full-end];
    > * {
        grid-column: main-start/main-end;
    }
`;

const ArticleTag = styled.div`
    margin-bottom: 16px;
    font-size: 1.6rem;
    a {
        color: var(--color-secondary-text);
    }
`;

const PrimaryTag = styled(InternalLink)`
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.01em;
    margin-bottom: 12px;
    text-transform: uppercase;
    color: #8acdd6;
`;

const ArticleTitle = styled.h1`
    margin-bottom: 0;
    font-size: clamp(3.2rem, 5vw, 5.2rem);
    font-weight: 800;
    line-height: 1;
    color: var(--color-darkgrey);
`;

const ArticleExcerpt = styled.p`
    margin-top: 20px;
    max-width: 720px;
    font-size: 2rem;
    line-height: 1.45;
    color: var(--color-darkgrey);
`;

const ArticleByline = styled.div`
    display: flex;
    justify-content: space-between;
    margin: min(24px, 5.6vmin) 0 0;
`;

const ArticleBylineContent = styled.section`
    flex-grow: 1;
    display: flex;
    align-items: center;
    ul {
        justify-content: flex-start;
        padding: 0 14px 0 0;
    }
`;

const AuthorList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 4px;
    padding: 0;
    list-style: none;
`;

const AuthorListItem = styled.li`
    position: relative;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
`;

const AuthorAvator = styled(InternalLink)`
    display: block;
    overflow: hidden;
    margin: 0 -4px;
    width: min(56px, 13.6vmin);
    height: min(56px, 13.6vmin);
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: var(--color-border);
    div {
        background-color: #e6e6e6;
        img {
            display: block;
            width: 100%;
            height: 100%;
            background: #fff;
            border-radius: 100%;
            -o-object-fit: cover;
            object-fit: cover;
        }
    }
`;

const ArticleBylineMeta = styled.div`
    color: var(--color-secondary-text);
    font-size: 1.4rem;
    line-height: 1.2em;
    > span {
        display: inline-block;
        margin: 0 2px;
    }
`;

const AuthorName = styled.h4`
    margin: 0 0 6px;
    font-size: 1.7rem;
    font-weight: 700;
    letter-spacing: 0;
    a {
        color: #15171a;
        text-decoration: none;
    }
`;

const ArticleImage = styled.figure`
    grid-column: wide-start/wide-end;
    width: 100%;
    margin: max(6.4vmin, 40px) 0 0;
    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    figcaption {
        padding: 1.5rem 1.5rem 0;
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
        font-size: 1.3rem;
        line-height: 1.4em;
    }
`;

const FooterCta = styled.section`
    position: relative;
    text-align: center;
    padding: 0 max(4vmin, 20px);
`;

const Inner = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
`;

const FooterCtaTitle = styled.h2`
    margin: 0 0 min(24px, 6.4vmin);
    font-size: clamp(2.6rem, 5vw, 3.8rem);
    font-weight: 800;
`;

const FooterCtaButton = styled(InternalLink)`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
    padding: 5px 5px 5px 15px;
    font-size: 1.7rem;
    color: var(--color-secondary-text);
    background: #fff;
    border: 1px solid var(--color-border);
    border-radius: 8px;
    transition: border-color 0.2s;
    &:hover {
        text-decoration: none;
        border-color: #c2c2c2;
    }
    span {
        display: inline-block;
        padding: 9px 15px;
        color: #fff;
        font-size: 1.6rem;
        font-weight: 600;
        letter-spacing: -0.005em;
        background: var(--ghost-accent-color);
        border-radius: 6px;
    }
`;

const ReadMoreWrapper = styled.section`
    margin-top: max(12vmin, 72px);
    position: relative;
    padding: 0 max(4vmin, 20px);
`;

const ReadMoreInner = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 4vmin;

    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    .post-card {
        position: relative;
        grid-column: span 2;
        display: flex;
        flex-direction: column;
        background-size: cover;
        word-break: break-word;
        .post-card-title {
            margin: 0;
            font-size: 2.6rem;
            font-weight: 800;
        }
    }

    @media (max-width: 1000px) {
        grid-template-columns: repeat(4, 1fr);
        article:nth-child(3) {
            display: none;
        }
    }

    @media (max-width: 700px) {
        grid-template-columns: repeat(2, 1fr);
        article:nth-child(3),
        article:nth-child(2) {
            display: none;
        }
    }
`;
/* eslint-disable react/prop-types */
export const Head = ({ data, location }) => {
    const metaProps = { data, location, type: "article" };
    const page = data.ghostPostPage;
    const { post } = page;

    return (
        <>
            <style type="text/css">{`${post.codeinjection_styles}`}</style>

            <GhostHead metaProps={metaProps} />
        </>
    );
};
/* eslint-enable react/prop-types */

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 */

const Post = ({ data }) => {
    const page = data.ghostPostPage;
    const { post } = page;
    const relatedPosts = data.allGhostPost.edges;
    const readingTime = readingTimeHelper(post);

    return (
        <Layout>
            <main className="site-main">
                <Article>
                    <ArticleHeader>
                        <ArticleTag>
                            {post.primary_tag && (
                                <PrimaryTag
                                    to={`/tag/${post.primary_tag.slug}/`}
                                >
                                    {post.primary_tag.name}
                                </PrimaryTag>
                            )}
                        </ArticleTag>
                        <ArticleTitle>{post.title}</ArticleTitle>
                        {post.custom_excerpt && (
                            <ArticleExcerpt>
                                {post.custom_excerpt}
                            </ArticleExcerpt>
                        )}
                        <ArticleByline>
                            <ArticleBylineContent>
                                <AuthorList>
                                    {post.authors.map((author) => (
                                        <AuthorListItem key={author.slug}>
                                            <AuthorAvator
                                                to={`/author/${author.slug}/`}
                                            >
                                                <img
                                                    src={
                                                        author.profile_image ||
                                                        "/icons/avatar.svg"
                                                    }
                                                    alt={author.name}
                                                />
                                            </AuthorAvator>
                                        </AuthorListItem>
                                    ))}
                                </AuthorList>
                                <ArticleBylineMeta>
                                    <AuthorName>
                                        <InternalLink
                                            to={`/author/${post.authors[0].slug}/`}
                                        >
                                            {post.primary_author.name}
                                        </InternalLink>
                                    </AuthorName>

                                    <time
                                        dateTime={parseDateTime(
                                            post.published_at_pretty
                                        )}
                                    >
                                        {formatDate(post.published_at_pretty)}
                                    </time>
                                    {readingTime && (
                                        <span>
                                            <span>{"•"}</span>
                                            {readingTime}
                                        </span>
                                    )}
                                </ArticleBylineMeta>
                            </ArticleBylineContent>
                        </ArticleByline>
                        {page.index === 0 && (
                            <ArticleImage>
                                <img
                                    srcSet={`
                                    ${post.feature_image + '?size="s"'} 300w,
                                    ${post.feature_image + '?size="m"'} 600w,
                                    ${post.feature_image + '?size="l"'} 1000w,
                                    ${post.feature_image + '?size="xl"'} 2000w,
                                `}
                                    sizes="(min-width: 1400px) 1400px, 92vw"
                                    src={post.feature_image}
                                    alt={post.feature_image_alt || post.title}
                                />
                                {post.feature_image_caption && (
                                    <figcaption
                                        dangerouslySetInnerHTML={{
                                            __html: post.feature_image_caption,
                                        }}
                                    />
                                )}
                            </ArticleImage>
                        )}
                    </ArticleHeader>
                    <PostContent html={page.html} />
                    <Pagination
                        pages={post.pagination}
                        currentPage={page.index}
                    />
                </Article>
            </main>
            <FooterCta>
                <Inner>
                    <FooterCtaTitle>
                        {"Sign up for more like this."}
                    </FooterCtaTitle>
                    <FooterCtaButton to="#/portal">
                        {"Enter your email"}
                        <span>{"Subscribe"}</span>
                    </FooterCtaButton>
                </Inner>
            </FooterCta>
            {relatedPosts && (
                <ReadMoreWrapper>
                    <ReadMoreInner>
                        {relatedPosts.map(({ node }) => (
                            <PostCard key={node.id} post={node} />
                        ))}
                    </ReadMoreInner>
                </ReadMoreWrapper>
            )}
        </Layout>
    );
};

Post.propTypes = {
    pageContext: PropTypes.shape({
        html: PropTypes.array,
        pageIndex: PropTypes.number,
    }).isRequired,
    data: PropTypes.shape({
        ghostPostPage: PropTypes.shape({
            index: PropTypes.number,
            html: PropTypes.string,
            post: PropTypes.shape({
                primary_author: PropTypes.object,
                primary_tag: PropTypes.object,
                codeinjection_styles: PropTypes.object,
                title: PropTypes.string.isRequired,
                feature_image: PropTypes.string,
                feature_image_alt: PropTypes.string,
                published_at_pretty: PropTypes.string,
                custom_excerpt: PropTypes.string,
                authors: PropTypes.array,
                feature_image_caption: PropTypes.string,
                slug: PropTypes.string,
                pagination: PropTypes.arrayOf(PropTypes.string).isRequired,
            }).isRequired,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
};

export default Post;

export const postQuery = graphql`
    query ($slug: String!, $url: String!, $tag: String) {
        ghostPostPage(url: { eq: $url }) {
            index
            html
            post {
                feature_image_caption
                ...GhostPostFields
            }
        }
        allGhostPost(
            filter: {
                slug: { ne: $slug }
                tags: { elemMatch: { name: { eq: $tag } } }
            }
            limit: 3
            sort: { fields: published_at, order: DESC }
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
