import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import InternalLink from "components/InternalLink";

const PaginationWrapper = styled.nav`
    display: flex;
    justify-content: center;
`;
const PaginationButton = styled(InternalLink)`
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    background-color: ${({ partiallyActive }) =>
        partiallyActive ? "#e2e2e2" : "#fff"};
    text-align: center;
    color: #8bccd5;
    width: 40px;
    font-size: 20px;
    margin: 10px;
`;

const Pagination = ({ pages, currentPage }) => {
    return (
        pages?.length > 1 && (
            <PaginationWrapper>
                {pages.map((page, index) => (
                    <PaginationButton
                        key={page}
                        partiallyActive={currentPage === index}
                        to={page}
                    >
                        {index + 1}
                    </PaginationButton>
                ))}
            </PaginationWrapper>
        )
    );
};

Pagination.propTypes = {
    pages: PropTypes.arrayOf(PropTypes.string),
    currentPage: PropTypes.number,
};

export default Pagination;
